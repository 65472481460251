var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.buscar.apply(null, arguments)}}},[_c('v-container',{staticClass:"py-0",attrs:{"luid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"5"}},[_c('SeletorDeLojasDoUsuario',{attrs:{"prepend-icon":"mdi-store","label":"Loja","clearable":"","idDaLojaSalva":_vm.filtro.lojaId},model:{value:(_vm.filtro.lojaId),callback:function ($$v) {_vm.$set(_vm.filtro, "lojaId", $$v)},expression:"filtro.lojaId"}})],1),_c('v-col',{attrs:{"cols":"5","sm":"6"}},[_c('RangeDatePicker',{attrs:{"inicial":{
								label: 'Data Inicial',
								clearable: true,
							},"final":{
								label: 'Data Final',
								clearable: true,
							}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('v-text-field',{ref:"campoDeNumeroDaNota",attrs:{"color":"success","label":"Nº Nota","min":"0","type":"number","inputmode":"numeric","prepend-icon":"mdi-receipt theme"},model:{value:(_vm.filtro.numeroNotaFiscal),callback:function ($$v) {_vm.$set(_vm.filtro, "numeroNotaFiscal", $$v)},expression:"filtro.numeroNotaFiscal"}})],1),_c('v-col',{attrs:{"cols":"1","sm":"2"}},[_c('v-text-field',{attrs:{"color":"success","label":"Série","type":"text"},model:{value:(_vm.filtro.serieFiscal),callback:function ($$v) {_vm.$set(_vm.filtro, "serieFiscal", $$v)},expression:"filtro.serieFiscal"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-select',{attrs:{"label":"Selecione o status...","items":[
								'Em trânsito',
								'Inconsistente',
								'Recebido',
								'Processando',
								'Cancelado' ],"multiple":"","value":"Incompleto"},model:{value:(_vm.filtro.status),callback:function ($$v) {_vm.$set(_vm.filtro, "status", $$v)},expression:"filtro.status"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"grey darken-2","text":"","disabled":_vm.buscando},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.limparFiltros.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-broom ")]),_vm._v(" Limpar Filtros ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","width":"110","type":"submit","target":"_blank"}},[_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Buscar ")],1),(_vm.podeDeletarNotaEmTransito)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.clickRemover}},[_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-delete-alert ")]),_vm._v(" Remover Notas Em Trânsito ")],1):_vm._e()],1)],1)],1),_c('Confirmacao',{ref:"confirmarCancelamento",attrs:{"width":"380","opcaoPadrao":"Não","titulo":"Deseja remover os registros de notas em trânsito?","subtitulo":"Todas as notas com status 'Em Trânsito' serão removidas do sistema de acordo com os filtros da tela atual"},on:{"confirmar":_vm.removerTodosPorFiltros}})],1),_c('v-card',[_c('DataTableDeCrud',{attrs:{"hideDelete":"","hideCreate":"","hideUpdate":"","hideDetail":false,"headers":_vm.headers,"loading":_vm.carregando,"items":_vm.notasFormatadas,"options":_vm.paginacao,"server-items-length":_vm.totalRegistros,"footer-props":{ itemsPerPageOptions: [5, 10, 15, 30, 50] },"show-select":_vm.podeDeletarNotaEmTransito},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
							var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.copiarTexto(item)}}},tooltip),[_vm._v(" "+_vm._s('mdi-key-variant')+" ")])]}}],null,true)},[(!_vm.chaveCopiada)?_c('span',[_vm._m(0)]):(!_vm.chaveCopiada && _vm.erroAoCopiarChave)?_c('span',[_vm._m(1)]):_c('span',[_vm._m(2)])])],_c('Confirmacao',{attrs:{"width":"300","titulo":"Deseja receber nota?","subtitulo":("<div>" + (item.dataHoraEmissao) + "</div>")},on:{"confirmar":function () { return _vm.receberNota(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var dialog = ref.on;
return [(item.status != 'Recebido')?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.receberNotas.includes(item.id)}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s(_vm.receberNotas.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-receipt-text-arrow-left-outline')+" ")])]}}],null,true)},[_vm._v(" Receber Nota ")]):_vm._e()]}}],null,true)}),_c('Confirmacao',{attrs:{"width":"325","titulo":"Deseja imprimir nota?","subtitulo":"Aviso: A nota gerada não possui valor fiscal","opcaoPadrao":"Não"},on:{"confirmar":function () { return _vm.imprimirNotaSemValorFiscal(item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var tooltip = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.imprimirNotas.includes(item.id)}},Object.assign({}, dialog, tooltip)),[_vm._v(" "+_vm._s(_vm.imprimirNotas.includes(item.id) ? 'mdi-loading mdi-spin' : 'mdi-printer-outline')+" ")])]}}],null,true)},[_vm._v(" Imprimir Nota ")])]}}],null,true)})],2)]}},{key:"item.status",fn:function(ref){
							var item = ref.item;
return [_c('span',{class:_vm.statusColor(item.status)},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.chaveDanfe",fn:function(ref){
							var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('div',_vm._g({staticClass:"mensagem-de-erro"},on),[_vm._v(" "+_vm._s(item.chaveDanfe)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.chaveDanfe))])])]}},{key:"item.log",fn:function(ref){
							var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('div',_vm._g({staticClass:"mensagem-de-erro"},on),[_vm._v(" "+_vm._s(item.log)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.log))])])]}}],null,true),model:{value:(_vm.notasSelecionadas),callback:function ($$v) {_vm.notasSelecionadas=$$v},expression:"notasSelecionadas"}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("Copiar chave danfe")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("Erro ao copiar chave danfe")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("Chave danfe copiada")])}]

export { render, staticRenderFns }